import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-pagination-not-url',
    templateUrl: './pagination-not-url.component.html',
    styleUrls: ['./pagination-not-url.component.css']
})
export class PaginationNotUrlComponent implements OnInit, OnChanges {
    private readonly PAGE: number = 1;
    private readonly COUNT: number = 30;
    private readonly COUNT_PAGE: number = 5;

    @Input() total: number;
    @Input() page: number = this.PAGE;
    @Input() count: number = this.COUNT;

    @Output('paginClick') click = new EventEmitter<any>();


    public first: number = 1;
    public prev: number = 1;
    public next: number = 2;
    public pageEnd: number = 1;
    public list: number[] = [];

    constructor(private _activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.updateState();
    }

    emitClick(page) {
        this.page = page;
        this.updateState();
        this.click.emit({
            page: this.page,
            count: this.count
        });
    }
    ngOnChanges() {
        this.updateState();
    }

    updateState() {
        if (this.page > 1) {
            this.prev = this.page - 1;
        } else {
            this.prev = this.first;
        }

        if (this.total) {
            this.pageEnd = Math.ceil(this.total / this.count);
        }

        if (this.pageEnd > this.page) {
            this.next = this.page + 1;
        }


        this.list = [];
        if (this.pageEnd > this.COUNT_PAGE) {
            if (this.page <= this.COUNT_PAGE - 2) {
                for (let i = 1; i <= this.COUNT_PAGE; i++) {
                    this.list.push(i);
                }
            } else {
                if (this.page < this.pageEnd - this.COUNT_PAGE) {
                    for (let i = this.page - 2; i < this.page; i++) {
                        this.list.push(i);
                    }
                    for (let i = this.page; i < this.page + 3; ++i) {
                        this.list.push(i);
                    }
                } else {
                    const t = (this.pageEnd - this.COUNT_PAGE) + 1;
                    for (let i = t; i <= this.pageEnd; i++) {
                        this.list.push(i);
                    }
                }
            }
        } else {
            for (let i = 1; i <= this.pageEnd; i++) {
                this.list.push(i);
            }
        }

    }

}
