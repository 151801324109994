import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Config } from "@core/service/config";
import { Get, InGet, InPut } from "@core/service/api/methods/stories/interface";
import { ApiRequest, Response } from "@core/service/api/request";
import { ApiUtilsService } from "@core/service/api/utils";

@Injectable()
export class ApiStoriesService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка слайдов
     *
     * @return {Promise<Get>}
     */
    get(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/stories', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /*
     * создание нового слайда
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    post(obj?: InPut, files: File[] = []): Promise<any> {
        return this._request
            .create('arm/v1/stories', obj, files, true)
            .then((res: Response) => res.json);
    }

    getAttach(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/stories/attachments', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }



}

