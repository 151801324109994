import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as _ from 'lodash';

am4core.useTheme(am4themes_animated);

@Component({
    selector: "app-chart",
    templateUrl: "./chart.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, AfterViewInit {
    @Input() data: any;
    constructor() {

    }
    ngOnInit() {
    }

    makeAverageChart(data, id) {
        let interfaceColors = new am4core.InterfaceColorSet();

        let chart = am4core.create(id, am4charts.XYChart);

        chart.data = data;
        // the following line makes value axes to be arranged vertically.
        chart.bottomAxesContainer.layout = "horizontal";
        chart.bottomAxesContainer.reverseOrder = true;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields['category'] = "category";
        categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        categoryAxis.renderer.grid.template.strokeOpacity = 1;
        categoryAxis.renderer.grid.template.location = 1;
        categoryAxis.renderer.minGridDistance = 10;

        let valueAxis1 = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis1.tooltip.disabled = true;
        valueAxis1.renderer.baseGrid.disabled = true;
        valueAxis1.marginRight = 30;
        valueAxis1.min = 0;
        valueAxis1.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
        valueAxis1.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxis1.renderer.grid.template.stroke = interfaceColors.getFor("background");
        valueAxis1.renderer.grid.template.strokeOpacity = 1;
        valueAxis1.title.text = data.store_name;
        valueAxis1.title.fontSize = 12;

        let series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.dataFields['categoryY'] = "category";
        series1.dataFields['valueX'] = "value1";
        series1.xAxis = valueAxis1;
        series1.fill = am4core.color('#34bfa3');
        series1.stroke = am4core.color('#34bfa3');
        series1.name = "Series 1";
        series1.columns.template.tooltipText = "Рейтинг {category}: [bold]{valueX}[/]";
        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.fillOpacity = 0;
        bullet1.strokeOpacity = 0;
        bullet1.tooltipText = "{valueX}";

        _.forEach(document.querySelectorAll('[aria-labelledby]'), (item) => {
            item.setAttribute("style", "display: none !important")
        })
    }

    ngAfterViewInit() {
        this.makeAverageChart(this.data, this.data.ident);
    }

}