import { Injectable } from '@angular/core';
import { IMenu } from './interface';

@Injectable()
export class AsideNavService {

    constructor() {
    }

    /**
     * Список менюшки
     *
     * @returns {any[]}
     */
    static getListMenu(): any[] {
        return [
            {
                title: 'Вопросы/Ответы',
                link: false,
                icon: 'flaticon-info',
                type: 'questions',
                children: [{
                    title: 'Список категорий',
                    link: '/questions/category/list'
                }, {
                    title: 'Список вопросов',
                    link: '/questions/list'
                }]
            },
            {
                title: 'Истории',
                link: false,
                icon: 'flaticon-notes',
                type: 'stories',
                children: [{
                    title: 'Список',
                    link: '/stories/list'
                }]
            },
            {
                title: 'Промокоды',
                link: false,
                icon: 'flaticon-gift',
                type: 'promocodes',
                children: [{
                    title: 'Список',
                    link: '/promocodes/list'
                }]
            },
            {
                title: 'Параметры',
                link: false,
                icon: 'flaticon-rocket',
                type: 'params',
                children: [{
                    title: 'Список',
                    link: '/params/list'
                }]
            }
        ];
    }
}
