import { Injectable } from '@angular/core';
import { ApiRequest } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import { Response } from '@core/service/api/request';
import { Post } from './interface';

@Injectable()
export class ApiAuthCheckService extends ApiUtilsService {

    constructor(protected _request: ApiRequest, protected _activatedRoute: ActivatedRoute, protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Проверка токена
     *
     * @return {Promise<Post>}
     */
    post(): Promise<any> {
        return this._request.create('arm/v1/auth/check')
            .then((res: Response) => {
                return res.json;
            }).catch(() => {
                return { result: false };
            });
    }
}

