import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../../helpers';
import { Auth } from "@core/service/auth";

declare let mLayout: any;

@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public login: string = 'test';


    constructor(private _auth: Auth) {
        this.login = this._auth.getUser();
    }

    ngOnInit() {
    }

    ngAfterViewInit() {

        mLayout.initHeader();

    }

}