import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';
import {
    Get, InGet, InPut, InPutDelete,
    InPutXls
} from '@core/service/api/methods/promocodes/interface';

@Injectable()
export class ApiPromocodesService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }


    /*
     * Получение списка генераций промокодов
     *
     * @return {Promise<Get>}
     */
    getGenerations(obj?: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/promocodes', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }


    /*
     * генерация
     *
     * @return {Promise<Get>}
     */
    generation(obj?: InPut): Promise<any> {
        return this._request
            .create('arm/v1/promocodes', (obj))
            .then((res: Response) => res.json);
    }

    /*
     * обновление генерация
     *
     * @return {Promise<Get>}
     */
    updateGeneration(obj?: InPut): Promise<any> {
        return this._request
            .update('arm/v1/promocodes', (obj))
            .then((res: Response) => res.json);
    }

    /*
     * Загрузка файла
     *
     * @return {Promise<Get>}
     */
    downloadExcel(obj?: InPutXls): Promise<any> {
        return this._request
            .update('arm/v1/promocodes/load', (obj))
            .then((res: Response) => res.json);
    }

    deleteGen(obj?: InPutDelete): Promise<any> {
        return this._request
            .update('arm/v1/promocodes/delete', (obj))
            .then((res: Response) => res.json);
    }

}

