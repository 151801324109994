<div id="parent">
	<div class="row">
		<div class="col-md-6">
			<span class="my-stat" >{{(data.avg_rating) ? data.avg_rating : 0}}</span>
			<p>Средняя оценка</p>
		</div>
		<div class="col-md-6">
			<span class="my-stat">{{(data.count) ? data.count : 0}}</span>
			<p>Количество оценок</p>
		</div>
	</div>
	<div [id]="data.ident" style="height: 500px;"></div>
</div>