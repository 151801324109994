import { NgModule } from "@angular/core";
import { LayoutComponent } from "./layout/layout.component";

import { HeaderNavComponent } from "./header-nav/header-nav.component";
import { AsideNavComponent } from "./aside-nav/aside-nav.component";
import { FooterComponent } from "./footer/footer.component";
import { QuickSidebarComponent } from "./quick-sidebar/quick-sidebar.component";
import { ScrollTopComponent } from "./scroll-top/scroll-top.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HrefPreventDefaultDirective } from "../../directives/href-prevent-default.directive";
import { UnwrapTagDirective } from "../../directives/unwrap-tag.directive";
import { LogoutComponent } from "./header-nav/logout/logout.component";
import { FormsModule } from "@angular/forms";
import { PaginationNotUrlModule } from "../pagination-not-url/pagination-not-url.module";
import { ChartComponent } from "./chart/chart.component";
import { ChartDateComponent } from "./chart-date/chart-date.component";

@NgModule({
    declarations: [
        LayoutComponent,
        HeaderNavComponent,
        AsideNavComponent,
        FooterComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        LogoutComponent,
        ChartComponent,
        ChartDateComponent
    ],
    exports: [
        LayoutComponent,
        HeaderNavComponent,
        AsideNavComponent,
        FooterComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        HrefPreventDefaultDirective,
        ChartComponent,
        ChartDateComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        PaginationNotUrlModule
    ]
})
export class LayoutModule {
}