import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as _ from 'lodash';

am4core.useTheme(am4themes_animated);

@Component({
    selector: "app-chart-date",
    templateUrl: "./chart-date.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./chart-date.component.css']
})
export class ChartDateComponent implements OnInit, AfterViewInit {
    @Input() data: any;
    constructor() {

    }
    ngOnInit() {
    }

    makeAverageChart(data, id) {
        let interfaceColors = new am4core.InterfaceColorSet();

        let chart = am4core.create(id, am4charts.XYChart);

        chart.data = data;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields['category'] = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.title.paddingBottom = 20;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 5;

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields['valueY'] = "rating";
        series.dataFields['categoryX'] = "date";
        series.name = "Visits";
        series.fill = am4core.color('#34bfa3');
        series.stroke = am4core.color('#34bfa3');
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;


        _.forEach(document.querySelectorAll('[aria-labelledby]'), (item) => {
            if (item.getAttribute("class") === null) {
                item.setAttribute("style", "display: none !important")
            }
        })
    }

    ngAfterViewInit() {
        this.makeAverageChart(this.data, this.data.ident);
    }

}