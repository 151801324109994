export const environment = {
    production: false,
    api: {
        url: 'lekopttorg-api.vigroup.ru',
        secret: 'adK7rPLInfPkoQX8TszxbwLjkRAGr9DcSS2JytEVHqQu35pNTsi2DxlNpiASDR8u',
        port: '',
        protocol: 'https',
        ncp: true,
        header: {
            'Content-Type': 'application/json',
            'platform': 3
        }
    },
    debug: false,
    site: {
        profitable: 100,
        date: {
            full: 'DD.MM.YYYY HH:mm',
            time: 'HH:mm',
            date: 'DD.MM.YYYY',
            dateBirth: 'DD.MM'
        },
        def: {
            elementCount: 15,
            startPage: 1,
            autoCompleteCount: 10,
            perPageList: [5, 15, 25],
            showPageCount: 7
        },
        updateTime: 5, // Интервал обновления данных, в секундах
        checkAuthTime: 5, // Интервал проверки авторизации, в секундах
        logoutTime: 30, // Время бездействия после которого нужна будет авторизация, в минутах
    },

};
