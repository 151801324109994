import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';

import * as _ from 'lodash';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // wrap in delayed observable to simulate server api call
        return of(null).pipe(
            mergeMap(() => {


                // // Группы
                // if ((request.url.indexOf('/ma/arm/v1/groups') !== -1 && request.method === 'GET')) {
                //     const body = {
                //         list: [
                //             { id: 1, name: 'group 1', parent_id: null },
                //             { id: 2, name: 'group 2', parent_id: null },
                //             { id: 3, name: 'group 3', parent_id: null },
                //             { id: 4, name: 'group 4', parent_id: null },
                //             { id: 5, name: 'group 5', parent_id: null },
                //             { id: 6, name: 'group 1-1', parent_id: 1 },
                //             { id: 7, name: 'group 2-1', parent_id: 2 },
                //             { id: 8, name: 'group 3-1', parent_id: 3 },
                //             { id: 9, name: 'group 4-1', parent_id: 4 },
                //             { id: 10, name: 'group 5-1', parent_id: 5 },
                //             { id: 11, name: 'group 1-2', parent_id: 1 },
                //             { id: 12, name: 'group 2-2', parent_id: 2 },
                //             { id: 13, name: 'group 3-2', parent_id: 3 },
                //             { id: 14, name: 'group 4-2', parent_id: 4 },
                //             { id: 15, name: 'group 5-2', parent_id: 5 },
                //         ],
                //     };
                //     return of(new HttpResponse({ status: 200, body: body }));
                // }

                // Авторизация
                // if (request.url.endsWith('/ma/arm/v1/auth/check/') && request.method === 'POST') {
                //     const body = {
                //         result: true,
                //     };
                //     return of(new HttpResponse({ status: 200, body: body }));
                // }

                // Создание пушей
                /*if (request.url.endsWith('/arm/v1/notifications/') && request.method === 'POST') {
                    const body = {
                        result: true,
                    };
                    return of(new HttpResponse({ status: 200, body: body }));
                }*/


                if (request.url.match(/arm\/v1\/specials\/statuses\/\?/) && request.method === 'GET') {

                    // 1 - черновик
                    // 2 - активная
                    // 3 - архивная

                    const body = {
                        list: [{
                            id: 1,
                            name: 'черновик'
                        },
                        {
                            id: 2,
                            name: 'активная'
                        },
                        {
                            id: 3,
                            name: 'архивная'
                        }],
                        total: 3
                    };

                    return of(new HttpResponse({ status: 200, body: body }));
                }

                if (request.url.indexOf('/ma/arm/v1/specials') !== -1 && request.method === 'GET') {

                    // 1 - черновик
                    // 2 - активная
                    // 3 - архивная

                    const body = {
                        id: 53,
                        title: 'Очень нормальная акция',
                        preview_descr: 'bohemian rhapsody',
                        preview_image_url: 'https://megapteka-api.vigroup.ru/upload/ma/specials/custom/norm2.jpg',
                        detail_descr: 'Is this the real life?↵Is this just fantasy?↵Caught in a landslide↵No escape from reality↵Open your eyes↵Look up to the skies and see↵I\'m just a poor boy, I need no sympathy↵Because I\'m easy come, easy go↵A little high, little low↵Anyway the wind blows, doesn\'t really matter to me, to me',
                        detail_image_url: 'https://megapteka-api.vigroup.ru/upload/ma/specials/custom/norm2.jpg',
                        is_main: true,
                        seo_description: 'seo_description',
                        seo_title: 'seo_title',
                        is_mobile_visible: true,
                        is_site_visible: true,
                        status_id: 2,
                        date_publish: 1529582435,
                        active_days: 5,
                        sort: 100,
                        is_deleted: false,
                        specials_items: [{
                            item_id: 41878,
                            item_name: 'Дексаметазон ампулы 4мг/мл 1мл N 25',
                            item_image_url: 'https://saas-apteka-api.vigroup.ru/upload/items/barcode/4607037190603.png'
                        }, {
                            item_id: 38792,
                            item_name: 'Дексаметазон КРКА ампулы 4мг/мл 1мл N 25',
                            item_image_url: 'https://saas-apteka-api.vigroup.ru/upload/items/barcode/3838989501235.png'
                        }, {
                            item_id: 38445,
                            item_name: 'Диазолин драже 50мг N 10',
                            item_image_url: 'https://saas-apteka-api.vigroup.ru/upload/items/barcode/4601808008319.png'
                        }]
                    };

                    return of(new HttpResponse({ status: 200, body: { list: [body], total: 1 } }));
                }
                //
                // // create user
                // if (request.url.endsWith('/api/users') && request.method === 'POST') {
                //     // get new user object from post body
                //     let newUser = request.body;
                //
                //     // validation
                //     let duplicateUser = users.filter(user => { return user.username === newUser.username; }).length;
                //     if (duplicateUser) {
                //         return Observable.throw('Username "' + newUser.username + '" is already taken');
                //     }
                //
                //     // save new user
                //     newUser.id = users.length + 1;
                //     users.push(newUser);
                //     localStorage.setItem('users', JSON.stringify(users));
                //
                //     // respond 200 OK
                //     return Observable.of(new HttpResponse({ status: 200 }));
                // }
                //
                // // delete user
                // if (request.url.match(/\/api\/users\/\d+$/) && request.method === 'DELETE') {
                //     // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                //     if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
                //         // find user by id in users array
                //         let urlParts = request.url.split('/');
                //         let id = parseInt(urlParts[urlParts.length - 1]);
                //         for (let i = 0; i < users.length; i++) {
                //             let user = users[i];
                //             if (user.id === id) {
                //                 // delete user
                //                 users.splice(i, 1);
                //                 localStorage.setItem('users', JSON.stringify(users));
                //                 break;
                //             }
                //         }
                //
                //         // respond 200 OK
                //         return Observable.of(new HttpResponse({ status: 200 }));
                //     } else {
                //         // return 401 not authorised if token is null or invalid
                //         return Observable.throw('Unauthorised');
                //     }
                // }

                // pass through any requests not handled above
                return next.handle(request);
            }));
    }
}

export let fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
