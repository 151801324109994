<!--Вверхние меню-->
<app-header-nav></app-header-nav>
<!-- begin::Body -->
<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
    <app-aside-nav></app-aside-nav>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
<!-- end:: Body -->
<app-footer></app-footer>
