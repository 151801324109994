import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { Routes, RouterModule } from '@angular/router';
import { Guard } from '@core/service/auth/guard';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [Guard],
        canActivateChild: [MetaGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
            },
            {
                path: 'questions',
                loadChildren: './questions/questions.module#QuestionsModule',
            },
            {
                path: 'stories',
                loadChildren: './stories/stories.module#StoriesModule',
            },
            {
                path: 'promocodes',
                loadChildren: './promocodes/promocodes.module#PromocodesModule',
            },
            {
                path: 'params',
                loadChildren: './params/params.module#ParamsModule',
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            }
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {
}
