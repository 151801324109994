import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: './auth/auth.module#AuthModule',
        canActivateChild: [MetaGuard],
        data: {
            meta: {
                title: 'Авторизация',
                override: true
            }
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
