<ul class="pagination" *ngIf="pageEnd > 1">
    <li *ngIf="page > 1">
        <a (click)="emitClick(first)"
           title="Первая"
           class="page-link">
            «
        </a>
    </li>
    <li *ngFor="let i of list">
        <a  (click)="emitClick(i)"
            [ngClass]="{active: i === page}"
            class="page-link"
            title="{{i}}">{{i}}</a>
    </li>
    <li *ngIf="pageEnd > page">
        <a (click)="emitClick(pageEnd)"
           title="Последняя"
           class="page-link" >
            »
        </a>
    </li>
</ul>