import { Injectable } from '@angular/core';

@Injectable()
export class LazyScript {
    private scriptLoadingPromise: Promise<void>;
    constructor() { }

    public load(_url: string, _async: boolean = false, _defer: boolean = false) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = _async;
        script.defer = _defer;
        script.src = _url;

        // tslint:disable-next-line:ban-types
        this.scriptLoadingPromise = new Promise<void>((resolve: Function, reject: Function) => {
            script.onload = () => {
                resolve();
            };
            script.onerror = (error: Event) => {
                reject(error);
            };
        });
        document.body.appendChild(script);
        return this.scriptLoadingPromise;
    }
}
