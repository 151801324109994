import { Injectable } from '@angular/core';
import { ApiAuthCheckService } from './methods/auth-check';
import { ApiAuthLoginService } from './methods/auth-login';
import { ApiAuthLogoutService } from './methods/auth-logout';
import { ApiQuestionsService } from "@core/service/api/methods/questions";
import { ApiElemsAttachmentsService } from "@core/service/api/methods/elems-attachments";
import { ApiItemsService } from "@core/service/api/methods/items";
import { ApiStoriesService } from "@core/service/api/methods/stories";
import { ApiPromocodesService } from "@core/service/api/methods/promocodes";
import { ApiParamsService } from "@core/service/api/methods/params";


@Injectable()
export class ApiService {
    constructor(
        public authCheck: ApiAuthCheckService,
        public authLogin: ApiAuthLoginService,
        public authLogout: ApiAuthLogoutService,
        public questions: ApiQuestionsService,
        public stories: ApiStoriesService,
        public elemsAttachment: ApiElemsAttachmentsService,
        public items: ApiItemsService,
        public promocodes: ApiPromocodesService,
        public params: ApiParamsService
    ) {
    }

}

