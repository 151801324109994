/* Module */
import { NgModule } from '@angular/core';

/* Test */
import { fakeBackendProvider } from './mock';

/* Service */
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from '@core/service/api/api';
import { ApiAuthCheckService } from '@core/service/api/methods/auth-check';
import { ApiAuthLoginService } from '@core/service/api/methods/auth-login';
import { ApiAuthLogoutService } from '@core/service/api/methods/auth-logout';
import { ApiRequest } from '@core/service/api/request';
import { ApiQuestionsService } from "@core/service/api/methods/questions";
import { ApiElemsAttachmentsService } from "@core/service/api/methods/elems-attachments";
import { ApiItemsService } from "@core/service/api/methods/items";
import { ApiStoriesService } from "@core/service/api/methods/stories";
import { ApiPromocodesService } from "@core/service/api/methods/promocodes";
import { ApiParamsService } from "@core/service/api/methods/params";

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [
        ApiRequest,
        ApiService,
        ApiAuthCheckService,
        ApiAuthLoginService,
        ApiAuthLogoutService,
        ApiQuestionsService,
        ApiStoriesService,
        ApiElemsAttachmentsService,
        ApiItemsService,
        ApiPromocodesService,
        ApiParamsService,
        // api backend simulation
        fakeBackendProvider,
    ],
    exports: []
})

export class ApiModule {
}
