<!-- begin::Quick Sidebar -->
<div id="m_quick_sidebar" class="m-quick-sidebar m-quick-sidebar--tabbed m-quick-sidebar--skin-light" appunwraptag="">
	<div class="m-quick-sidebar__content m--hide">
		<span id="m_quick_sidebar_close" class="m-quick-sidebar__close">
			<i class="la la-close"></i>
		</span>
		<ul id="m_quick_sidebar_tabs" class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand" role="tablist">
			<li class="nav-item m-tabs__item">
				<a class="nav-link m-tabs__link active" data-toggle="tab" href="#m_quick_sidebar_tabs_messenger" role="tab">
					Messages
				</a>
			</li>
			<li class="nav-item m-tabs__item">
				<a class="nav-link m-tabs__link" 		data-toggle="tab" href="#m_quick_sidebar_tabs_settings" role="tab">
					Settings
				</a>
			</li>
			<li class="nav-item m-tabs__item">
				<a class="nav-link m-tabs__link" data-toggle="tab" href="#m_quick_sidebar_tabs_logs" role="tab">
					Logs
				</a>
			</li>
		</ul>
		<div class="tab-content">
			<div class="tab-pane active m-scrollable" id="m_quick_sidebar_tabs_messenger" role="tabpanel">
				<div class="m-messenger m-messenger--message-arrow m-messenger--skin-light">
					<div class="m-messenger__messages">
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--in">
								<div class="m-messenger__message-pic">
									<!--<img src="./assets/app/media/img//users/user3.jpg" alt=""/>-->
								</div>
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-username">
											Megan wrote
										</div>
										<div class="m-messenger__message-text">
											Hi Bob. What time will be the meeting ?
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--out">
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-text">
											Hi Megan. It's at 2.30PM
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--in">
								<div class="m-messenger__message-pic">
									<!--<img src="./assets/app/media/img//users/user3.jpg" alt=""/>-->
								</div>
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-username">
											Megan wrote
										</div>
										<div class="m-messenger__message-text">
											Will the development team be joining ?
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--out">
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-text">
											Yes sure. I invited them as well
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__datetime">
							2:30PM
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--in">
								<div class="m-messenger__message-pic">
									<!--<img src="./assets/app/media/img//users/user3.jpg"  alt=""/>-->
								</div>
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-username">
											Megan wrote
										</div>
										<div class="m-messenger__message-text">
											Noted. For the Coca-Cola Mobile App project as well ?
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--out">
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-text">
											Yes, sure.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--out">
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-text">
											Please also prepare the quotation for the Loop CRM project as well.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__datetime">
							3:15PM
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--in">
								<div class="m-messenger__message-no-pic m--bg-fill-danger">
									<span>
										M
									</span>
								</div>
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-username">
											Megan wrote
										</div>
										<div class="m-messenger__message-text">
											Noted. I will prepare it.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--out">
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-text">
											Thanks Megan. I will see you later.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="m-messenger__wrapper">
							<div class="m-messenger__message m-messenger__message--in">
								<div class="m-messenger__message-pic">
									<!--<img src="./assets/app/media/img//users/user3.jpg"  alt=""/>-->
								</div>
								<div class="m-messenger__message-body">
									<div class="m-messenger__message-arrow"></div>
									<div class="m-messenger__message-content">
										<div class="m-messenger__message-username">
											Megan wrote
										</div>
										<div class="m-messenger__message-text">
											Sure. See you in the meeting soon.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="m-messenger__seperator"></div>
					<div class="m-messenger__form">
						<div class="m-messenger__form-controls">
							<input type="text" name="" placeholder="Type here..." class="m-messenger__form-input">
						</div>
						<div class="m-messenger__form-tools">
							<a href="" class="m-messenger__form-attachment">
								<i class="la la-paperclip"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane  m-scrollable" id="m_quick_sidebar_tabs_settings" role="tabpanel">
				<div class="m-list-settings">
					<div class="m-list-settings__group">
						<div class="m-list-settings__heading">
							General Settings
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Email Notifications
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" checked="checked" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Site Tracking
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								SMS Alerts
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Backup Storage
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Audit Logs
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" checked="checked" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
					</div>
					<div class="m-list-settings__group">
						<div class="m-list-settings__heading">
							System Settings
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								System Logs
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Error Reporting
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Applications Logs
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Backup Servers
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" checked="checked" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
						<div class="m-list-settings__item">
							<span class="m-list-settings__item-label">
								Audit Logs
							</span>
							<span class="m-list-settings__item-control">
								<span class="m-switch m-switch--outline m-switch--icon-check m-switch--brand">
									<label>
										<input type="checkbox" name="">
										<span></span>
									</label>
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane  m-scrollable" id="m_quick_sidebar_tabs_logs" role="tabpanel">
				<div class="m-list-timeline">
					<div class="m-list-timeline__group">
						<div class="m-list-timeline__heading">
							System Logs
						</div>
						<div class="m-list-timeline__items">
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									12 new users registered
									<span class="m-badge m-badge--warning m-badge--wide">
										important
									</span>
								</a>
								<span class="m-list-timeline__time">
									Just now
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									System shutdown
								</a>
								<span class="m-list-timeline__time">
									11 mins
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-danger"></span>
								<a href="" class="m-list-timeline__text">
									New invoice received
								</a>
								<span class="m-list-timeline__time">
									20 mins
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-warning"></span>
								<a href="" class="m-list-timeline__text">
									Database overloaded 89%
									<span class="m-badge m-badge--success m-badge--wide">
										resolved
									</span>
								</a>
								<span class="m-list-timeline__time">
									1 hr
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									System error
								</a>
								<span class="m-list-timeline__time">
									2 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									Production server down
									<span class="m-badge m-badge--danger m-badge--wide">
										pending
									</span>
								</a>
								<span class="m-list-timeline__time">
									3 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									Production server up
								</a>
								<span class="m-list-timeline__time">
									5 hrs
								</span>
							</div>
						</div>
					</div>
					<div class="m-list-timeline__group">
						<div class="m-list-timeline__heading">
							Applications Logs
						</div>
						<div class="m-list-timeline__items">
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									New order received
									<span class="m-badge m-badge--info m-badge--wide">
										urgent
									</span>
								</a>
								<span class="m-list-timeline__time">
									7 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									12 new users registered
								</a>
								<span class="m-list-timeline__time">
									Just now
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									System shutdown
								</a>
								<span class="m-list-timeline__time">
									11 mins
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-danger"></span>
								<a href="" class="m-list-timeline__text">
									New invoices received
								</a>
								<span class="m-list-timeline__time">
									20 mins
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-warning"></span>
								<a href="" class="m-list-timeline__text">
									Database overloaded 89%
								</a>
								<span class="m-list-timeline__time">
									1 hr
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									System error
									<span class="m-badge m-badge--info m-badge--wide">
										pending
									</span>
								</a>
								<span class="m-list-timeline__time">
									2 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									Production server down
								</a>
								<span class="m-list-timeline__time">
									3 hrs
								</span>
							</div>
						</div>
					</div>
					<div class="m-list-timeline__group">
						<div class="m-list-timeline__heading">
							Server Logs
						</div>
						<div class="m-list-timeline__items">
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									Production server up
								</a>
								<span class="m-list-timeline__time">
									5 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									New order received
								</a>
								<span class="m-list-timeline__time">
									7 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									12 new users registered
								</a>
								<span class="m-list-timeline__time">
									Just now
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									System shutdown
								</a>
								<span class="m-list-timeline__time">
									11 mins
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-danger"></span>
								<a href="" class="m-list-timeline__text">
									New invoice received
								</a>
								<span class="m-list-timeline__time">
									20 mins
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-warning"></span>
								<a href="" class="m-list-timeline__text">
									Database overloaded 89%
								</a>
								<span class="m-list-timeline__time">
									1 hr
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									System error
								</a>
								<span class="m-list-timeline__time">
									2 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									Production server down
								</a>
								<span class="m-list-timeline__time">
									3 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-success"></span>
								<a href="" class="m-list-timeline__text">
									Production server up
								</a>
								<span class="m-list-timeline__time">
									5 hrs
								</span>
							</div>
							<div class="m-list-timeline__item">
								<span class="m-list-timeline__badge m-list-timeline__badge--state-info"></span>
								<a href="" class="m-list-timeline__text">
									New order received
								</a>
								<span class="m-list-timeline__time">
									1117 hrs
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end::Quick Sidebar -->
