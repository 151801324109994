/* Service */
import { NgModule } from '@angular/core';
import { Config } from './service/config';
import { Log } from './service/logger';
import { Auth, Guard } from './service/auth';
import { BackUrl } from './service/back-url';
import { Session } from './service/session';
import { LsService } from './service/localstorage';
import { Checking } from './service/checking';
import { Refresh } from './service/refresh';
import { Toast } from './service/toast';
import { StoreService } from './service/store';
import { WindowRefService } from './service/window';
import { Cookie } from './service/cookie';

/*Module*/
import { CookieModule } from 'ngx-cookie';
import { HttpClientModule } from '@angular/common/http';
import { LazyScript } from './service/lazy-script';
import { ToastrModule } from 'ngx-toastr';

/*Component*/

/* Test */
import { fakeBackendProvider } from './service/api/mock';
import { ApiModule } from '@core/service/api/module';

@NgModule({
    imports: [
        ApiModule,
        CookieModule.forRoot(),
        ToastrModule.forRoot(),
        HttpClientModule],
    declarations: [],
    providers: [
        Config,
        Log,
        Auth,
        Guard,
        BackUrl,
        Session,
        LsService,
        Checking,
        Refresh,
        Toast,
        StoreService,
        WindowRefService,
        Cookie,
        LazyScript
    ],
    exports: [ApiModule]
})

export class CoreModule {
}
