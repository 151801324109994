import { Injectable } from '@angular/core';
import { ApiRequest } from './../../request';
import { InPost } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiAuthLoginService extends ApiUtilsService {

    constructor(protected _request: ApiRequest, protected _activatedRoute: ActivatedRoute, protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Получения токена
     *
     * @param {InPost} obj
     * @return {Promise<InPost>}
     */
    put(obj: InPost) {
        return this._request.update('arm/v1/auth/login', obj);
    }
}

