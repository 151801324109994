import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../request';
import { Get, InGet, InPut } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../utils';

@Injectable()
export class ApiQuestionsService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /**
     * Список пользователей
     *
     * @param {InGet} obj
     * @returns {Promise<Get>}
     */
    getCategory(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/questions/category', obj)
            .then((res: Response) => res.json);
    }

    /*
     * создание/обновление категории
     *
     * @return {Promise<Interface.ListSpecials>}
     */
    updateCategory(obj?: any, files: File[] = []): Promise<any> {
        return this._request
            .create('arm/v1/questions/category', obj, files)
            .then((res: Response) => res.json);
    }

    update(obj?: any, files: File[] = []): Promise<any> {
        return this._request
            .create('arm/v1/questions', obj, files)
            .then((res: Response) => res.json);
    }

    get(obj?: InGet): Promise<any> {
        return this._request
            .query('arm/v1/questions', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

}
