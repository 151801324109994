import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { IMenu } from './interface';
import { AsideNavService } from './aside-nav.service';

declare let mLayout: any;

@Component({
    selector: 'app-aside-nav',
    templateUrl: './aside-nav.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    public list: IMenu[];

    constructor() { }

    ngOnInit() {
        this.list = AsideNavService.getListMenu();
    }

    ngAfterViewInit() {
        mLayout.initAside();
    }
}
