import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationNotUrlComponent } from './pagination-not-url.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    declarations: [PaginationNotUrlComponent],
    exports: [PaginationNotUrlComponent]
})
export class PaginationNotUrlModule {
}
