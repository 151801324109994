import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { LayoutModule } from './shared/modules/layouts/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from './shared/services/script-loader.service';
import { PagesModule } from './pages/pages.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from '@core/module';
import { MetaModule } from '@ngx-meta/core';
@NgModule({
    declarations: [
        PagesComponent,
        AppComponent
    ],
    imports: [
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        PagesModule,
        AuthModule,
        CoreModule,
        MetaModule.forRoot()
    ],
    providers: [ScriptLoaderService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
